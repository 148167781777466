import * as Icons from "@phosphor-icons/react";
import { getGlobalStats, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Counter from "../Counter";
import DataConstants from "const/DataConstants";
import React from "react";
import UiRoutes from "const/UiRoutes";

const { TRANSACTIONS } = UiRoutes;

const { STATUSES: { TO_RECONCILE } } = DataConstants;

const TransactionsAwaitingClassificationCounter = () => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const globalStats = useSelector(getGlobalStats);

  const businessId = useSelector(getSelectedBusinessId);

  const {
    transactions: {
      toReconcile: { count } = {}
    } = {}
  } = globalStats;

  return (
    <Counter
      to={`/${businessId}${TRANSACTIONS}?status=${TO_RECONCILE}`}
      theme="royal-blue"
      mainText={count || 0}
      secondaryText={uiTexts.transactions.toLowerCase()}
      description={messages.dashboardTxsNeedClassification}
      icon={Icons.Question}
      loading={!globalStats.transactions} />
  );
};

export default React.memo(TransactionsAwaitingClassificationCounter);
