import * as Icons from "@phosphor-icons/react";
import {
  getGlobalStats,
  getSelectedBusinessId
} from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Counter from "../Counter";
import DataConstants from "const/DataConstants";
import React from "react";
import UiRoutes from "const/UiRoutes";

const { TRANSACTIONS } = UiRoutes;

const { STATUSES: { TO_REVIEW } } = DataConstants;

const TransactionsAwaitingReviewCounter = () => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const globalStats = useSelector(getGlobalStats);

  const businessId = useSelector(getSelectedBusinessId);

  const {
    transactions: {
      toReview: { count } = {}
    } = {}
  } = globalStats;

  return (
    <Counter
      to={`/${businessId}${TRANSACTIONS}?status=${TO_REVIEW}`}
      theme="primary"
      mainText={count || 0}
      secondaryText={uiTexts.transactions.toLowerCase()}
      description={messages.dashboardTxsNeedReview}
      icon={Icons.Repeat}
      loading={!globalStats.transactions} />
  );
};

export default React.memo(TransactionsAwaitingReviewCounter);
