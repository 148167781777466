import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getGlobalStats, getSelectedBusinessId } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Counter from "../Counter";
import React from "react";
import UiRoutes from "const/UiRoutes";

const { AUDIT } = UiRoutes;

const MonthEndCloseCounter = () => {
  const { uiTexts, messages } = useSelector(getTextsData);

  const globalStats = useSelector(getGlobalStats);

  const businessId = useSelector(getSelectedBusinessId);

  const {
    audit: {
      total: { count } = {}
    } = {}
  } = globalStats;

  return (
    <Counter
      to={`/${businessId}${AUDIT}`}
      theme="danger"
      mainText={count || 0}
      secondaryText={uiTexts.issues.toLowerCase()}
      description={messages.dashboardAuditIssues}
      icon={Icons.Flag}
      loading={!globalStats.transactions}
      className={Css.monthEndCloseCounter} />
  );
};

export default React.memo(MonthEndCloseCounter);
